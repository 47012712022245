import React, { useMemo } from 'react'
import moment from 'moment'
import { CustomInput } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'

import CopyableLink from '../components/common/CopyableLink'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { Table, Header, HeaderCell, NarrowCell, TextCell, isColumn } from '../components/common/Table'
import { VetsExpanded_users } from '../hasura/graphQlQueries/types/VetsExpanded'
import { isSuperAdmin } from '../lib/helpers'
import { updateVetActiveAction, usersSelector, UsersState } from '../hasura/slices/users'

// @ts-ignore
import slackIds from '../../slackIds.json'

const defaultColumn: any = {
  Cell: TextCell,
}

export default function Staff() {
  const dispatch = useDispatch()

  const { accessToken, vetsExpanded, user, role }: UsersState = useSelector(usersSelector)

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Created',
          accessor: (v: VetsExpanded_users) => moment(v.created_at).fromNow(),
        },
        {
          Header: 'Name',
          accessor: 'display_name',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        isSuperAdmin(role) && {
          Header: 'Enterprises',
          accessor: (v: VetsExpanded_users) =>
            v.user_roles
              .map((r) => r.enterprise.short_name)
              .sort()
              .join(', '),
        },
        {
          Header: 'Active',
          accessor: (v: VetsExpanded_users) => v,
          Cell: (v: { value: VetsExpanded_users }) => (
            <div>
              <CustomInput
                id={v.value.id}
                className="m-0 custom-switch-light"
                role="button"
                type="switch"
                checked={v.value.active}
                onClick={() => {
                  if (!user) return

                  dispatch(updateVetActiveAction(accessToken, v.value.id, user.organization.enterprise.id, !v.value.active))
                }}
              />
            </div>
          ),
        },
        isSuperAdmin(role) && {
          Header: 'Notes',
          accessor: (v: VetsExpanded_users) => {
            const hasPhoto = Boolean(v.related_accounts?.auth0_profile?.user_metadata?.image)
            const hasSlackId = Boolean(slackIds[v.id])
            return [hasPhoto ? '' : 'needs photo', hasSlackId ? '' : 'needs slack ID'].filter(Boolean).join(', ')
          },
        },
      ].filter((obj) => obj),
    [accessToken, user]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: vetsExpanded,
      defaultColumn,
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, 'Name') || isColumn(data, 'Email') ? 2 : 1)

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between">
          <h4 className="bold">Staff</h4>
        </div>

        <div className="max-width-1100px">
          <div className="border rounded width-fit-content text-s p-3">
            <CopyableLink
              prompt="To invite specialists to your clinic, send them your unique link:"
              link={`vet.radimal.ai/enterprise?i=${
                user?.organization.enterprise.id
              }&r=vet&n=${user?.organization.enterprise.short_name.toLowerCase().replace(/ /g, '-')}`}
            />
          </div>

          <Table cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, idx: number) => (
                    <HeaderCell
                      noBorder
                      key={idx}
                      {...column.getHeaderProps(column.getSortByToggleProps({}))}
                      colSpan={colSpan(column)}
                    >
                      {column.Header && <Header>{column.render('Header')}</Header>}
                    </HeaderCell>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx: number) => {
                      return (
                        <NarrowCell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell.column)}>
                          {cell.render('Cell')}
                        </NarrowCell>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </MainBox>
    </Layout>
  )
}
